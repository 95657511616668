<template>
    <div>
      <FormulaBanner :title="title" :formulaCode="formulaCode"/>
      <div class="index">
        <!-- 表单 -->
        <div>
          <div class="top_nav"><span></span>{{title}}
          </div>
          <div class="formBody">
            <!-- 表单 -->
            <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
            <TemplateFiveList :type="'radio'" :list-data="listData" :selectValue="listSelect" @change="listChange"></TemplateFiveList>
            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button class="formula-template-button" block type="info" @click="submit" style="width:90px">提交</van-button>
            </div>
            <!-- 计算结果 -->
            <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''"> -->
              <!-- <div class="CalculationResults"><span>计算结果</span></div> -->
              <!-- 具体标准 -->
              <!-- <div>
                <div class="CalculationDetail"><span></span>{{result}}分 {{countText}}</div>
              </div> -->
              <!--            <div>-->
              <!--              <div class="CalculationDetail"><span></span>具体标准：</div>-->
              <!--            </div>-->
            <!-- </div> -->
          </div>
        </div>
        <CalculationResults :countText="countValue" :countTextList="countTextList" v-if="show"></CalculationResults>
        <!-- 后台设置的值 -->
        <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
        <!-- 参考文献 -->
        <FormulaReferences :references-data="tableData.references"></FormulaReferences>
      </div>
  
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
            title="应激指数"
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
        />
      </van-popup>
  
    </div>
  
  </template>
  
  <script>
  import router from "@/router";
  import MyPatient from '@/components/MyPatient.vue'
  import FormulaBanner from '@/components/FormulaBanner.vue'
  import {Toast} from "vant";
  import {getAdmins} from "@/utils/adminDate";
  import {setById} from "@/service/base";
  import {getFormula, getFormulaHuiXian, postFormula} from "@/service/BodyMassIndex_api";
  import FormulaExplain from "@/components/template1/FormulaExplain";
  import FormulaReferences from "@/components/template1/FormulaReferences";
  import TemplateFiveList from "@/components/Template5/TemplateFiveList";
  import CalculationResults from "@/components/CalculationResults";
  export default {
    name: "medicalSearch",
    data(){
      return{
        compute:true,
        show:false,
        countValue:"",
        listSelect: [],
        listData: [
          {
            title: '面部表情',
            columns: ['未观察到肌肉紧张（自然、放松）','表现出皱眉、眉毛放低、眼眶紧绷和提肌收缩（紧张）','所有以上面部表情加上眼睑轻度闭合(面部扭曲)'],
            fenzhi: [0,1,2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '肢体活动',
            columns: ['不动(不代表不存在疼痛)','缓慢、谨慎地运动，触碰或抚摸疼痛部位，通过运动寻求关注（保护性体动）','拉拽管道，试图坐起来，运动肢体 / 猛烈摆动，不遵从指挥令，攻击工作人员，试图从床上爬出来(烦乱不安)'],
            fenzhi: [0,1,2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '肌肉紧张（通过被动的弯曲和伸展来评估）',
            columns: ['对被动的运动动作不作抵抗（放松）','对被动的运动动作抵抗（紧张和肌肉僵硬）','对被动的运动动作剧烈抵抗，无法将其完成（非常紧张或僵硬）'],
            fenzhi: [0,1,2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
          {
            title: '对呼吸机的顺应性（气管插管患者）',
            columns: ['无警报发生，舒适地接受机械通气（耐受呼吸机或机械通气）','警报自动停止(咳嗽但是耐受)','不同步：机械通气阻断，频繁报警(对抗呼吸机)'],
            fenzhi: [0,1,2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
           {
            title: '发声（拔管后的患者）',
            columns: ['用正常腔调讲话或不发声','叹息，呻吟','喊叫，啜泣'],
            fenzhi: [0,1,2] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
          },
         
  
        ],
        formulaCode: '',
        shengao: '',
        yingjizhishu: '',
        title: '',
        memberId: '1111',
        selectValue: '',
        username:"",
        value:"",
        showPopover: false,
        showPicker: false,
        columns: ['', '', '',''],
        nianling: '',
        xingbie: null,
        tableData: {},
        countText: '',
        patientId: null,
        result: null,
        yingjizhishuzhi: '',
        tizhong: '',
        xueqingnanongdu: '',
        fenzhiList: [],
        countTextList:[],
        arr:[],
      }
    },
    components: {
      FormulaBanner,
      MyPatient,
      FormulaExplain,
      FormulaReferences,
      TemplateFiveList,
      CalculationResults
    },
    async created() {
      await this.test()
      await this.getTableData()
    },
  
    methods:{
      listChange (result, fenzhi) {
        this.show=false
        this.listSelect = result //结果数组
        this.fenzhiList = fenzhi  //分值数组
        // 选了四不能选5，选了5不能选4
        result.map((item,index  ) => {
          if(item.title === '对呼吸机的顺应性（气管插管患者）' && item.value.length !== 0){
            var index4 = index
            // 如果再选到了5，就删除4的东西
            result.map((item) =>{
              if(item.title === '发声（拔管后的患者）' && item.value.length !== 0){ 
                this.listSelect[index4] = []
              }
            })
          }else if(item.title === '发声（拔管后的患者）' && item.value.length !== 0){
            var index5 = index
            result.map((item) =>{
              if(item.title === '对呼吸机的顺应性（气管插管患者）' && item.value.length !== 0){ 
                this.listSelect[index5] = []
              }
            })
          }
        })
      },
      coumputTetxt () {
        this.show=true
        let fenshu = 0
        function calculateScore(data, select) {
          select.value.forEach(item => {
            const index = data.columns.indexOf(item);
            if (index !== -1) {
              fenshu += data.fenzhi[index];
            }
          });
        }
        this.listSelect.forEach(value => {
          const data = this.listData.find(d => d.title === value.title);
          if (data) {
            calculateScore(data, value);
          }
        });
        this.result = fenshu
        this.countValue = `${fenshu}分`
      },
      async test(){
        //像接口头部传用户信息
        let arrays = await getAdmins(this.$route.query.memberId,this.$route.query.channel)
        this.channel = arrays.os
        this.version = arrays.version
        this.ip = arrays.ip
        await setById(arrays)
      },
      async getTableData () {
        const data = {
          channel: this.$router.currentRoute.query.channel,
          code: router.currentRoute.path.substring( 9,router.currentRoute.path.Length),
        }
        const res = await getFormula(data.channel, data.code)
        if (res.code === 0) {
          this.tableData = res.data
          this.formulaCode = res.data.code
          this.title = res.data.chineseName
        }
        if (this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId) {
        
          const data = {
            id: this.$router.currentRoute.query.logId
          }
          const res = await getFormulaHuiXian(data)
          if (res.code === 0) {
            this.listSelect = res.data.content.data
            if (res.data.patientId) this.selectValue = {id: res.data.patientId, name: res.data.patientName}
            this.fenzhiList = [res.data.result.value]
            this.result = res.data.result.value
            this.countText = res.data.result.result
            this.countValue=res.data.result.value + '分' + res.data.result.result
            this.show=true
            
          }
        }
      },
      async submit () {
        this.arr = this.listSelect.filter(function(item) {
          return  item.length !== 0
        });
        if (this.arr.length < 4) {
          Toast.fail('请完善选择')
          return
        }
        this.coumputTetxt()
        const data = {
          id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
          patientId: this.patientId,
          //   columnCode: this.tableData.columnCode, JZZYX
          columnCode: "JZZYX", 
          formulaCode: this.tableData.code,
          channel: this.$router.currentRoute.query.channel,
          location: this.$router.currentRoute.query.location,
          content: {
            data: this.listSelect
            // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
          },
          result: {
            value: this.result,
            result: this.countText,
            DisplayResults:this.countValue
          }
        }
        const res = await postFormula(data)
        if (res.code === 0) {
          Toast.success('提交成功')
        } else {
          Toast.fail(res.message)
        }
      },
      calculateAge(birthDateString) {
        const today = new Date();
        const birthDate = new Date(birthDateString);
        let age = today.getFullYear() - birthDate.getFullYear();
        // 如果今天的日期还没到生日月份和日期，则年龄减一
        if (today.getMonth() < birthDate.getMonth() ||
            (today.getMonth() === birthDate.getMonth() && today.getDate() < birthDate.getDate())) {
          age--;
        }
        return age;
      },
      // 开参考文献链接
      openLink(u){
        if (!u) {
          return
        }
        //获取系统版本
        let userAI = navigator.userAgent;
        const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
        const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
        if (isAndroid) {
          // alert('安卓操作系统')
          // 这个是安卓操作系统
          window.open(u)
        }
        if (isIOS) {
          // 这个是ios操作系统
          // alert('ios操作系统')
          window.location.href = u
        }
      },
      yingjiClick () {
        this.showPicker = true
      },
      onConfirm(value) {
        this.yingjizhishu = value
        this.showPicker = false
      },
      onCancel() {
        this.showPicker = false
      },
      async onSubmit(){
        this.coumputTetxt()
        const data = {
          id: this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' ? this.$router.currentRoute.query.logId : null,
          patientId: this.patientId,
        //   columnCode: this.tableData.columnCode, JZZYX
          columnCode: "JZZYX", 
          formulaCode: this.tableData.code,
          channel: this.$router.currentRoute.query.channel,
          location: this.$router.currentRoute.query.location,
          content: {
            shengao: {value: this.shengao, unit: 'cm'},
            tizhong: {value: this.tizhong, unit: 'kg'},
            nianling: {value: this.nianling, unit: '岁'},
            xingbie: {value: this.xingbie == 1 ? '男' : '女'},
            // xueqingnanongdu: {value: this.xueqingnanongdu, unit: 'mmol/L'},
          },
          result: {}
        }
        if (this.xingbie == 1) {
          data.result.nanxing = {value: this.result, unit: 'kcal'}
        } else {
          data.result.nvxing = {value: this.result, unit: 'kcal'}
        }
        const res = await postFormula(data)
        if (res.code === 0) {
          Toast.success('提交成功')
        } else {
          Toast.fail(res.message)
        }
      },
      userChange (data) {
        if (data.birthday && data.birthday !== '') {
          this.nianling = this.calculateAge(data.birthday)
        } else {
          this.nianling = ''
        }
        this.xingbie = data.sex
        this.patientId = data.id
        this.selectValue = data
      },
    }
  }
  </script>
  
  
  <style scoped lang="scss">
  .index{
    background: #F6F6F6;
    padding: 10px 15px;
    min-height: 100vh;
    .StandardDescription{
      margin-top:10px;
      .top_nav{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        span {
          display: inline-block;
          width: 5px;
          height: 15px;
          background: #259beb;
          margin-right: 5px;
        }
      }
      .StandardDeatil{
        width:100%;
        padding:15px 10px;
        //   height: 50px;
        background-color: #fff;
        box-sizing: border-box;
      }
    }
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .formBody{
      background-color: #fff;
      padding-bottom:10px;
      .CalculationResultsBox{
        padding:0 8px;
        .CalculationResults{
          font-size: 16px;
          color: #333333;
          font-weight: bold;
          display: flex;
          justify-content: center;
          margin-bottom:10px
        }
        .CalculationDetail{
          font-size: 15px;
          color: #333333;
          // font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: start;
          margin-bottom:10px;
          background-color: #f6f6f6;
          // height: 16px;
          span{
            display: inline-block;
            width: 3px;
            height: 24px;
            background: #fe9712;
            margin-right: 5px;
          }
        }
      }
    }
    .titles{
      display:flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      height: 50px;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 10px;
      box-sizing: border-box;
      .titlesName{
        font-size:16px;
        i{
          color: red;
        }
      }
      .information{
        font-size:16px;
        margin-right:4px;
        .PleaseSelect{
          color: #b9b9b9;
        }
        .vanCell{
          width:50px;
          padding:0;
          height: 100%;
        }
      }
      .vBotton{
        width:60px
      }
      .unit{
        height: 50px;
        //   line-height: 50px;
        display: flex;
        align-items: center;
        font-size:16px;
        width:20px;
      }
      .vanIcon{
        font-size:13px;
        color:#a8a8a8;
        font-weight: 650;
      }
    }
  }
  ::v-deep .van-cell {
    padding:0px 0px;
  }
  ::v-deep .van-field__control{
    height: 50px;
    font-size: 16px;
  }
  ::v-deep .van-field__error-message {
    position: absolute;
    top: 30px;
    left: 10px;
  }
  .StandardDeatil2{
    width:100%;
    padding: 15px 10px;
    padding-bottom:1px;
    //   height: 50px;
    background-color: #fff;
    box-sizing: border-box;
    .references{
      margin-bottom:15px
    }
  }
  ::v-deep p {
    padding: 0;
    margin: 0;
  }
  .Abutton {
  
  }
  </style>
  